<template>
  <div class="ac1">
    <!-- <nav-header></nav-header> -->
    <c3></c3>
    <div class="container">
      <!-- 面包屑导航 -->
      <nav class="ac1-list">
        <a href="javascript:;" @click="goHome()">首页</a>
        <span>></span>
        <a href="javascript:;">最新新闻</a>
      </nav>

      <!-- 标题 -->
      <div class="ac1-title">
        <h2>最新新闻</h2>
      </div>

      <!-- 内容 容器 -->
      <div class="ac1-con">
        <div class="ac1-title">
          <h2>网络文化经营许可证</h2>
        </div>
        <ul>
          <li
            v-for="(item, index) in this.list_data1"
            :key="index"
            @click="goDetails(item.id)"
          >
            <a href="javascript:;">{{ item.title }}</a
            ><span>{{ tdtime }}</span>
          </li>
        </ul>

        <div class="ac1-title">
          <h2>ICP</h2>
        </div>
        <ul>
          <li
            v-for="(item, index) in this.list_data2"
            :key="index"
            @click="goDetails(item.id)"
          >
            <a href="javascript:;">{{ item.title }}</a
            ><span>{{ tdtime }}</span>
          </li>
        </ul>

        <div class="ac1-title">
          <h2>EDI</h2>
        </div>
        <ul>
          <li
            v-for="(item, index) in this.list_data3"
            :key="index"
            @click="goDetails(item.id)"
          >
            <a href="javascript:;">{{ item.title }}</a
            ><span>{{ tdtime }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import c3 from "./../components/c3";
export default {
  name: "ac1",
  components: {
    c3,
  },
  data() {
    return {
      tdtime: "",
      href: "index.html",
      list_data1: [],
      list_data2: [],
      list_data3: [],
    };
  },
  mounted() {
    this.init();
    document.title = "最新新闻-网络文化经营许可证";
    var myDate = new Date();
    this.tdtime = myDate.toISOString().slice(0, 10);
  },
  methods: {
    init() {
      this.axios.get("/mock/info.json").then((res) => {
        const data = res.data;
        console.log(data);
        this.list_data1 = data.splice(0, 6);
        this.list_data2 = data.splice(0, 6);
        this.list_data3 = data.splice(0, 6);
      });
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { id: id },
      });
    },
    // 跳转首页
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.ac1 {
  width: 100%;
  background-color: #f2f2f2;
  height: auto;

  .container {
    background-color: #fff;
    margin: 10px auto;
    padding: 30px;
    box-sizing: border-box;
    // 面包屑导航
    .ac1-list {
      width: 100%;
      height: 30px;
      margin-bottom: 15px;
      a {
        font-size: 14px;
        color: #121212;
      }
      span {
        margin: 0 15px;
        font-weight: bold;
      }
    }

    // 标题
    .ac1-title {
      width: 100%;
      height: 40px;
      background-color: #dcdcdd;
      padding-left: 20px;
      margin-bottom: 20px;
      box-sizing: border-box;
      h2 {
        font-size: 21px;
        line-height: 40px;
        font-weight: 500;
      }
    }

    // 内容容器
    .ac1-con {
      width: 100%;
      height: 90%;
      border: 1px solid #dddddd;
      padding: 0 20px;
      box-sizing: border-box;
      padding-top: 20px;
      ul {
        list-style-type: disc;
        list-style-position: inside;
        border-bottom: 1px #dddddd solid;
        margin: 28px auto;
        li {
          width: 100%;
          // background-color: pink;

          height: 30px;
          line-height: 30px;
          margin: 15px auto;

          a {
            font-size: 18px;
            color: #000;
            font-size: 15px;
            margin-right: 30px;
            &:hover {
              color: #c60023;
            }
          }
          span {
            font-size: 12px;
            color: #888888;
          }
        }
      }
    }
  }
}
</style>
