<template>
  <footer class="nav-footer">
    <!-- 上层 -->
    <div class="f-s">
      <!-- 顶宽布局 -->
      <div class="container">
        <!-- logo -->
        <div class="f-logo">
          <p>
            坚果团队凭借专业技术团队及数千名主播管理经验已为北京，浙江，天津，四川等地上千家企业成功申办《网络文化经验许可证》，紧随政策优化，精准施策确保效率第一是我们追求的终极目标。
          </p>
        </div>
        <!-- 容器区块 -->
        <div class="f-con">
          <!-- 区块1 -->
          <div class="f-item">
            <a href="javascript:;" @click="goHome()">网站首页</a>
            <a href="javascript:;" @click="goAc3()">关于我们</a>
            <a href="javascript:;" @click="goAc1()">行业资讯</a>
            <a href="javascript:;" @click="goAc2()">许可证入口</a>
          </div>

          <!-- 区块2 -->
          <div class="f-item">
            <p>公司邮箱：jianguo@jg15.onexmail.com</p>
            <p>咨询热线：400-006-2021</p>
          </div>

          <div class="f-item">
            <div class="f-img">
              <img src="/imgs/ewm/company.jpg" alt="" />
              <h3>企业二维码</h3>
            </div>

            <div class="f-img">
              <img src="/imgs/ewm/personal.jpg" alt="" />
              <h3>24小时咨询</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 下层 -->
    <div class="f-x">
      <div class="container">
        <span
          >Copyright 2019-2021 坚果创世（北京）网络科技有限公司 版权所有</span
        >

        <span>
          <a href="https://beian.miit.gov.cn/#/Integrated/index"
            >京ICP备19025738号-2</a
          >
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "nav-footer",
  props: {
    email: String,
    phone: String,
    icp: String,
    name: String,
    addr: String,
  },
  methods: {
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { id: id },
      });
    },
    // 政治要闻
    goAc1() {
      this.$router.push("/ac1");
    },
    // 许可证入口
    goAc2() {
      this.$router.push("/ac2");
    },
    // 首页
    goHome() {
      this.$router.push("/");
    },
    // 关于我们
    goAc3() {
      this.$router.push("/ac3");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.nav-footer {
  width: 100%;
  height: 280px;
  // 上层
  .f-s {
    width: 100%;
    height: 220px;
    background-color: #bd1a2d;
    .container {
      @include flex();
      height: 100%;
      // logo
      .f-logo {
        width: 286px;
        height: 100%;
        padding-top: 50px;
        box-sizing: border-box;
        p {
          font-size: 13px;
          color: #e2e2e2;
          line-height: 30px;
        }
      }
      // 右侧容器列表
      .f-con {
        @include flex();
        height: 100%;
        // 区块
        .f-item {
          background-color: #a91728;
          width: 315px;
          height: 150px;
          margin: 0 3px;
          // 区块1
          &:nth-child(1) {
            // width: 250px;
            padding-top: 20px;
            box-sizing: border-box;
            a {
              display: inline-block;
              color: #e2e2e2;
              font-size: 14px;
              // font-weight: bold;
              margin: 18px 47px;
              transition: all 0.3s;
              &:hover {
                transform: translateX(-5px);
              }
            }
          }
          // 区块2
          &:nth-child(2) {
            p {
              color: #e2e2e2;
              font-size: 14px;
              text-align: left;
              // line-height: 150px;
              margin: 38px auto;
              padding: 0 30px;
              box-sizing: border-box;
            }
          }
          // 区块3
          &:nth-child(3) {
            width: 280px;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            .f-img {
              width: 80px;
              height: 100px;
              margin: 0 15px;
              img {
                width: 100%;
                height: 80px;
              }
              h3 {
                font-size: 14px;
                color: #e2e2e2;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
  // 下层
  .f-x {
    width: 100%;
    height: 55px;
    background-color: #dabb85;
    .container {
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
      span {
        font-size: 13px;
        color: #666;
        margin: 0 15px;
        a {
          font-size: 13px;
          color: #666;
        }
      }
    }
  }
}
</style>
