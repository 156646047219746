<template>
  <!-- 区块1 -->
  <div class="c1">
    <div class="container">
      <!-- 下层容器 -->
      <div class="c1-con">
        <!-- logo -->
        <div class="c2-logo"></div>
        <!-- 标题文字 -->
        <div class="c2-title">
          <h2>网络文化经营许可证</h2>
        </div>
        <!-- 英文标题 -->
        <div class="c2-title2">
          <h2>Business license of network culture</h2>
        </div>
        <!-- input 搜索框 -->
        <div class="c2-input">
          <input
            type="text"
            placeholder="直播为什么要需要网络文化经营许可证"
            v-model="vals"
            @keyup.13="gets()"
          />
          <span @click="gets()">搜索 <i class="iconfont icon-jinru"></i></span>
        </div>
        <!-- 热词搜索 -->
        <div class="c2-search">
          <span>热门：</span>
          <a
            href="javascript:;"
            v-for="(item, index) in this.search_list"
            :key="index"
            @click="goDetails(item.id)"
            >{{ item.name }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 搜索框内容
      vals: "",
      // 热搜词循环
      search_list: [
        {
          id: "13",
          name: "直播",
        },
        {
          id: "14",
          name: "直播许可证",
        },
        {
          id: "15",
          name: "文网文",
        },
        {
          id: "16",
          name: "网络表演",
        },
        {
          id: "17",
          name: "直播许可证代办",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { id: id },
      });
    },

    // 搜索逻辑
    gets() {
      if (this.vals == "") {
        alert("搜索关键字不能为空");
        return;
      }
      let jsonData = require("../../public/mock/info.json");
      let id = null;
      // console.log(jsonData);
      for (var i = 0; i < jsonData.length; i++) {
        if (jsonData[i].title.indexOf(this.vals) != -1) {
          id = jsonData[i].id;
          break;
        }
      }
      if (id != null) {
        this.goDetails(id);
      } else {
        alert("搜索结果为空");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.c1 {
  width: 100%;
  height: 600px;

  .container {
    height: 100%;
    position: relative;
    box-sizing: border-box;
    padding-top: 100px;
    // 绝对定位导航
    .c1-nav {
      width: 600px;
      height: 100px;
      position: absolute;
      display: flex;
      align-items: center;
      top: 30px;
      left: 0;

      // logo
      .c1-logo {
        width: 200px;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      // 右侧信息
      .c1-phone {
        margin-left: 30px;
        height: 60px;
        display: flex;
        align-items: center;
        color: #fff;
        i {
          font-size: 35px;
          font-weight: bold;
          margin-right: 10px;
        }
        .phone-info {
          p {
            font-size: 14px;
            margin: 8px auto;
          }
        }
      }
    }
    // 主容器
    .c1-con {
      text-align: center;
      //   background-color: #ff6600;
      width: 700px;
      height: 500px;
      margin: 0 auto;

      //logo
      .c2-logo {
        width: 200px;
        height: 100px;
        margin: 50px auto 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      //标题文字
      .c2-title {
        font-size: 26px;
        color: #c60023;
      }
      //英文标题
      .c2-title2 {
        font-size: 14px;
        color: #928d8d;
        margin: 10px auto 30px;
      }
      //搜索框
      .c2-input {
        width: 700px;
        height: 60px;
        //   background-color: #ff6600;
        display: flex;
        align-items: center;
        //   border-radius: 10px;
        //   input框
        input {
          height: 100%;
          width: 83%;
          border-style: none;
          border-radius: 10px 0px 0px 10px;
          // background-color: #c60023;
          box-sizing: border-box;
          padding-left: 20px;
          // border: 5px solid #eee;
        }
        // 搜索
        span {
          width: 17%;
          height: 100%;
          background-color: #c60023;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          border-radius: 0 10px 10px 0;
          color: #fff;
          cursor: pointer;
          i {
            font-size: 28px;
            margin-left: 5px;
          }
        }
      }

      //热搜词
      .c2-search {
        margin-top: 30px;
        text-align: left;
        span {
          font-size: 14px;
          // font-weight: bold;
          margin-right: 15px;
        }
        a {
          font-size: 14px;
          color: #121212;
          margin: 0 15px;
          &:hover {
            color: #c60023;
          }
        }
      }
    }
  }
}
</style>
