<template>
  <div class="ac2">
    <c3></c3>
    <!-- title 层 -->
    <div class="ac2-title">
      <!-- 上层 -->
      <div class="a2-s">
        <div class="container">
          <span @click="goHome()">首页</span>
          <span>></span>
          <span>网络文化经营许可证</span>
        </div>
      </div>

      <!-- 下层 -->
      <div class="a2-x">
        <div class="container">
          <!-- 左侧信息 -->
          <div class="a2-l">
            <h2>网络文化经营许可证</h2>
            <span>网上预约</span>
            <span>收费标准及依据</span>
          </div>

          <!-- 右侧简介 -->
          <div class="a2-r">
            <p>没有文网文资质许可证开展互联网文化活动会有什么处罚？</p>
            <p>
              根据《无照经营管理办法》进行处罚，没收企业从事
              相关活动的所有设备，根据企业从事互联网文化的进度处以一定金额的罚款。
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- 容器层 -->
    <div class="table-center">
      <div class="table-main clearfix">
        <div class="main-center">
          <!-- 基本信息 -->

          <!-- 办理流程 -->
          <div class="path-main" id="path-main">
            <p class="table-name">办理流程</p>
            <p class="path-wp _hidden">无</p>
            <table id="path-table" class="">
              <thead>
                <tr>
                  <th style="width: 100px">办理环节</th>
                  <th style="width: 110px">办理步骤</th>
                  <th style="width: 112px">办理时限</th>
                  <th style="width: 110px">办理人员</th>
                  <th style="width: 145px">审查标准</th>
                  <th style="width: 175px">办理结果</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="path-name" rowspan="3">申请受理</td>
                </tr>
                <tr>
                  <td class="path-name">申报/收件</td>
                  <td class="path-name">4个工作日</td>
                  <td style="text-align: center">综窗人员</td>
                  <td>
                    <span class="scslTitle">申请材料齐全。</span
                    ><span class="zkai Lzkai _hidden">【展开】</span>
                  </td>
                  <td>
                    <span class="scslTitle"
                      >符合审查标准的予以收件，不符合审查标准不予收件。</span
                    ><span class="zkai Lzkai _hidden">【展开】</span>
                  </td>
                </tr>
                <tr>
                  <td class="path-name">受理</td>
                  <td class="path-name">1个工作日</td>
                  <td style="text-align: center">综窗人员</td>
                  <td>
                    <span class="scslTitle"
                      >1.申请材料齐全、符合法定形式；2.按申请材料目录载明的顺序排列、加盖公章、扫描；3.所有材料扫描成一份，分辨率100dpi、灰度模式、pdf格式；4.上传的扫描材料命名为：公司名称+办理事项名称。</span
                    ><span class="zkai Lzkai _hidden">【展开】</span>
                  </td>
                  <td>
                    <span class="scslTitle"
                      >符合审查标准的予以受理，不符合审查标准不予受理。</span
                    ><span class="zkai Lzkai _hidden">【展开】</span>
                  </td>
                </tr>
                <tr class="path-name">
                  <td rowspan="2">审查与决定</td>
                </tr>
                <tr>
                  <td class="path-name">决定</td>
                  <td class="path-name">13个工作日</td>
                  <td style="text-align: center">首席代表或部门负责人</td>
                  <td>
                    <span class="scslTitle"
                      >1.确定的单位名称、住所、组织机构和章程；2.确定的互联网文化活动范围，电商类、教育类、医疗类、培训类、金融类、旅游类、美食类、体育类、聊天类经营内容依法不在《网络文化经营许可证》的管理范围，不需要申办《网络文化经营许可证》；3.适应互联网文化活动需要的专业人员、设备、工作场所以及相应的经营管理技术措施；4.确定的域名；5.营业执照经营范围中应有“从事互联网文化活动”。</span
                    >
                  </td>
                  <td>
                    <span class="scslTitle"
                      >符合审查标准，批准的，核发该事项批准文书；不符合审查标准，不予批准</span
                    ><span class="zkai Lzkai _hidden">【展开】</span>
                  </td>
                </tr>
                <tr class="path-name">
                  <td rowspan="4">颁证与送达</td>
                </tr>
                <tr>
                  <td class="path-name">制证</td>
                  <td class="path-name">4个工作日</td>
                  <td style="text-align: center">部门人员</td>
                  <td colspan="2" rowspan="2" class="_td">
                    <table class="_table1">
                      <tbody>
                        <tr>
                          <td class="_td1">结果名称</td>
                        </tr>
                        <tr>
                          <td>
                            <span class="Jname"
                              ><p>1、网络文化经营许可证正本</p>
                              <p>2、网络文化经营许可证副本</p></span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td class="path-name">发证</td>
                  <td class="path-name">1个工作日</td>
                  <td style="text-align: center">综窗人员</td>
                </tr>
                <tr>
                  <td rowspan="1" style="border-right: none">送达方式：</td>
                  <td colspan="4" style="border-left: none; text-align: left">
                    <span style="display: inline-block"
                      ><input
                        class="new_input ck_input"
                        checked="checked"
                        type="checkbox"
                        disabled="disabled"
                      />窗口领取</span
                    ><span style="display: none"
                      ><input
                        class="new_input ck_input"
                        checked="checked"
                        type="checkbox"
                        disabled="disabled"
                      />公告送达</span
                    ><span style="display: inline-block"
                      ><input
                        class="new_input ck_input"
                        checked="checked"
                        type="checkbox"
                        disabled="disabled"
                      />邮件寄送</span
                    ><span style="display: none"
                      ><input
                        class="new_input ck_input"
                        checked="checked"
                        type="checkbox"
                        disabled="disabled"
                      />网站下载:</span
                    ><span style="display: none">其他:</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- 申请材料 -->
          <div class="apply-main" id="apply-main">
            <p class="table-name">申请材料</p>
            <p class="apply-wp _hidden">无</p>

            <table id="apply-table">
              <thead>
                <tr>
                  <th style="width: 45px">序号</th>
                  <th style="width: 150px">材料名称</th>
                  <th style="width: 87px">材料来源</th>
                  <th style="width: 80px">材料<br />必要性</th>
                  <th style="width: 100px">数量要求</th>
                  <th style="width: 80px">介质要求</th>
                  <th style="width: 90px">表格及<br />样表下载</th>
                  <th style="width: 135px">其他要求</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="11">一</td>
                  <td>经营性互联网文化单位设立申请表（网上办理，免于提交）</td>
                  <td>申请人自备</td>
                  <td>必要</td>
                  <td>原件1份</td>
                  <td>表格类；纸质</td>
                  <td>不涉及</td>
                  <td>
                    <span
                      class="AUDIT_POINT skipSP"
                      promptname="受理标准"
                      cert="内容真实"
                      >受理标准&gt;</span
                    ><span
                      class="MATERIALSEVIDENCE skipSP"
                      promptname="提供材料依据"
                      cert="互联网文化管理暂行规定"
                      >提供材料依据&gt;</span
                    ><span
                      class="PAGEFORMAT skipSP"
                      promptname="材料规格"
                      cert=""
                    ></span
                    ><span
                      class="REMARK skipSP"
                      promptname="备注"
                      cert=""
                    ></span
                    ><span class="scslTitle">加盖公章、内容真实。</span
                    ><span class="zkai Lzkai _hidden">【展开】</span>
                  </td>
                </tr>
                <tr>
                  <td class="11">二</td>
                  <td>公司网站域名登记证书</td>
                  <td>
                    中介机构或法定机构产生<span
                      class="CERT_OFFICE skipSP"
                      promptname="来源说明"
                      cert="域名管理机构核发"
                    ></span>
                  </td>
                  <td>必要</td>
                  <td>正本复印件1份</td>
                  <td>结果文书类；纸质、电子</td>
                  <td><span class="gang">不涉及</span></td>
                  <td>
                    <span
                      class="AUDIT_POINT skipSP"
                      promptname="受理标准"
                      cert="在有效期内，加盖公章"
                      >受理标准&gt;</span
                    ><span
                      class="MATERIALSEVIDENCE skipSP"
                      promptname="提供材料依据"
                      cert="互联网文化管理暂行规定"
                      >提供材料依据&gt;</span
                    ><span
                      class="PAGEFORMAT skipSP"
                      promptname="材料规格"
                      cert=""
                    ></span
                    ><span
                      class="REMARK skipSP"
                      promptname="备注"
                      cert=""
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td class="11">三</td>
                  <td>业务说明</td>
                  <td>申请人自备</td>
                  <td>必要</td>
                  <td>原件1份</td>
                  <td>其他；纸质、电子</td>
                  <td><span class="gang">不涉及</span></td>
                  <td>
                    <span
                      class="AUDIT_POINT skipSP"
                      promptname="受理标准"
                      cert="音乐娱乐内容介绍、曲目简介，盈利模式，版权或授权材料，网站首页及音乐页面截图。"
                      >受理标准&gt;</span
                    ><span
                      class="MATERIALSEVIDENCE skipSP"
                      promptname="提供材料依据"
                      cert="互联网文化管理暂行规定"
                      >提供材料依据&gt;</span
                    ><span
                      class="PAGEFORMAT skipSP"
                      promptname="材料规格"
                      cert=""
                    ></span
                    ><span
                      class="REMARK skipSP"
                      promptname="备注"
                      cert=""
                    ></span
                    ><span class="scslTitle"
                      >加盖公章、音乐娱乐内容介绍、曲目简介，盈利模式，自有音乐产品的版权，非自有音乐产品还需版权方的授权，网站首页及音乐页面截图。</span
                    ><span class="zkai Lzkai _hidden">【展开】</span>
                  </td>
                </tr>
                <tr>
                  <td class="11">四</td>
                  <td>经营管理制度</td>
                  <td>申请人自备</td>
                  <td>必要</td>
                  <td>原件1份</td>
                  <td>其他；纸质、电子</td>
                  <td><span class="gang">不涉及</span></td>
                  <td>
                    <span
                      class="AUDIT_POINT skipSP"
                      promptname="受理标准"
                      cert="加盖公章、应包括：一、《用户权益保障管理制度》：涉及用户协议；交易纠纷处理方式、流程。二、《技术安全保障管理制度》：涉及网站安全保障措施；用户信息安全及保密管理制度。三、《音乐内容自审制度：参照《文化部关于实施<网络文化经营单位内容自审管理办法>的通知》（文市发〔2013〕39号）制定，应具体到自审人员姓名、职责、联系方式。"
                      >受理标准&gt;</span
                    ><span
                      class="MATERIALSEVIDENCE skipSP"
                      promptname="提供材料依据"
                      cert="互联网文化管理暂行规定"
                      >提供材料依据&gt;</span
                    ><span
                      class="PAGEFORMAT skipSP"
                      promptname="材料规格"
                      cert=""
                    ></span
                    ><span
                      class="REMARK skipSP"
                      promptname="备注"
                      cert=""
                    ></span
                    ><span class="scslTitle"
                      >加盖公章、应包括：一、《用户权益保障管理制度》：涉及用户协议；交易纠纷处理方式、流程。二、《技术安全保障管理制度》：涉及网站安全保障措施；用户信息安全及保密管理制度。三、《音乐内容自审制度：参照《文化部关于实施&lt;网络文化经营单位内容自审管理办法&gt;的通知》（文市发〔2013〕39号）制定，应具体到自审人员姓名、职责、联系方式。</span
                    >
                  </td>
                </tr>
                <tr class="">
                  <td class="11">五</td>
                  <td>委托授权书（网上办理，免于提交）</td>
                  <td>申请人自备</td>
                  <td>必要</td>
                  <td>原件1份</td>
                  <td>表格类；纸质</td>
                  <td><span class="gang">不涉及</span></td>
                  <td>
                    <span
                      class="AUDIT_POINT skipSP"
                      promptname="受理标准"
                      cert="限于非法定代表人前来办理的需提交并且粘贴代办人身份证复印件。"
                      >受理标准&gt;</span
                    ><span
                      class="MATERIALSEVIDENCE skipSP"
                      promptname="提供材料依据"
                      cert="互联网文化管理暂行规定"
                      >提供材料依据&gt;</span
                    ><span
                      class="PAGEFORMAT skipSP"
                      promptname="材料规格"
                      cert=""
                    ></span
                    ><span
                      class="REMARK skipSP"
                      promptname="备注"
                      cert=""
                    ></span
                    ><span class="scslTitle"
                      >加盖公章、限于非法定代表人前来办理的需提交并且粘贴代办人身份证复印件。</span
                    ><span class="zkai Lzkai _hidden">【展开】</span>
                  </td>
                </tr>
                <tr class="">
                  <td>申请材料总要求</td>
                  <td colspan="7">无</td>
                </tr>
              </tbody>
              <tfoot></tfoot>
            </table>

            <tfoot></tfoot>
          </div>

          <!-- 结果样本 -->
          <div class="sample-main" id="sample-main">
            <p class="table-name">结果名称及样本</p>
            <p class="sample-wp _hidden">无</p>
            <div id="sample-table">
              <table style="table-layout: auto; margin-bottom: 40px">
                <tbody>
                  <tr>
                    <td colspan="2" style="font-weight: 600">
                      网络文化经营许可证正本
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 128px">结果名称</th>
                    <td style="width: 759px">网络文化经营许可证正本</td>
                  </tr>
                  <tr>
                    <th style="width: 128px">结果文书类型</th>
                    <td style="width: 759px">证照</td>
                  </tr>
                  <tr>
                    <th style="width: 128px">结果样本</th>
                    <td style="width: 759px">
                      <a
                        href="javascript:;"
                        style="color: #d00201; text-decoration: underline"
                        @click="md1()"
                        >网络文化经营许可证正本样本.pdf</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 128px">有效时间</th>
                    <td style="width: 759px">三年</td>
                  </tr>
                </tbody>
              </table>
              <table style="table-layout: auto; margin-bottom: 40px">
                <tbody>
                  <tr>
                    <td colspan="2" style="font-weight: 600">
                      网络文化经营许可证副本
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 128px">结果名称</th>
                    <td style="width: 759px">网络文化经营许可证副本</td>
                  </tr>
                  <tr>
                    <th style="width: 128px">结果文书类型</th>
                    <td style="width: 759px">证照</td>
                  </tr>
                  <tr>
                    <th style="width: 128px">结果样本</th>
                    <td style="width: 759px">
                      <a
                        href="javacript:;"
                        style="color: #d00201; text-decoration: underline"
                        @click="md2()"
                        >网络文化经营许可证副本样本.pdf</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 128px">有效时间</th>
                    <td style="width: 759px">三年</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- 左侧列表 -->
        <div class="main-left">
          <span class="_hov"></span>
          <!-- <a class="basic-t" style="color: #cd0600" href="#basic-main"
            >基本信息</a
          > -->
          <a class="path-t" href="#path-main" style="color: #cd0600">办理流程</a
          ><a class="apply-t" href="#apply-main">申请材料</a
          ><a class="sample-t" href="#sample-main">结果名称及样本</a>
        </div>
        <!-- 右侧区块 -->
        <div class="main-right">
          <!-- 样本案例 -->
          <div class="promise">
            <p class="promise-title">证书案例</p>
            <swiper :options="swiperOption">
              <!-- 循环项 -->
              <swiper-slide
                v-for="(item, index) in this.swiperList"
                :key="index"
              >
                <a @click="goDetails2(item.id)"><img :src="item.img" /></a>
              </swiper-slide>
              <!-- <div class="swiper-pagination" slot="pagination"></div> -->
            </swiper>
          </div>
          <!-- 联系方式 -->
          <div class="promise" style="margin-top: 20px; height: 340px">
            <!-- 标题 -->
            <p class="promise-title p-ran">
              咨询方式
              <img class="zx open" src="" alt="" />
            </p>

            <p>
              <span class="promise-sp-title">电话：</span
              ><span style="width: 103px"> 400-006-2021 </span>
            </p>
            <p>
              <span class="promise-sp-title" style="width: 55px">邮箱：</span
              ><span style="font-size: 12px"> jianguo@jg15.onexmail.com </span>
            </p>
            <p style="text-align: center; color: #a2a2a2">
              <span style="width: 103px; height: 10px">企业二维码</span>
            </p>
            <p class="ewm">
              <img src="/imgs/ewm/company.jpg" alt="" />
            </p>
            <div class="zx-div"></div>
          </div>
        </div>
      </div>
      <modal
        title="样本展示"
        btnType="1"
        modalType="middle"
        :showModal="showModal"
        @submit="showModal = false"
        @cancel="showModal = false"
      >
        <template v-slot:body>
          <img  :src="mimg" />
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import Modal from "../components/Modal.vue";

import c3 from "./../components/c3";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  components: {
    // NavHeader,
    c3,
    Modal,
    Swiper,
    SwiperSlide,
  },
  methods: {
    // 跳转首页
    goHome() {
      this.$router.push("/");
    },
    md1() {
      this.mimg = "/imgs/xkz/东方仿真/z.jpg";
      this.showModal = true;
    },
    md2() {
      this.mimg = "/imgs/xkz/东方仿真/f.jpg";
      this.showModal = true;
    },
     // 跳转详情页
    goDetails2(id) {
      this.$router.push({
        path: "/sound2/:" + id,
        query: { id: id },
      });
    },
  },
  data() {
    return {
      // 是否显示弹框
      showModal: false,
      mimg: "",
      // 轮播配置
      swiperOption: {
        autoplay: true,
        loop: true,

        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      // 轮播数组
      swiperList: [
        {
          id: "5",
          img: "/imgs/xkz/圣塔菲/z.jpg",
        },
        {
          id: "5",
          img: "/imgs/xkz/圣塔菲/f.jpg",
        },
        {
          id: "4",
          img: "/imgs/xkz/日普乐/z.jpg",
        },
        {
          id: "4",
          img: "/imgs/xkz/日普乐/f.jpg",
        },
         {
          id: "6",
          img: "/imgs/xkz/亿知云峰网文/z.jpg",
        },
        {
          id: "6",
          img: "/imgs/xkz/亿知云峰网文/f.jpg",
        },
      ],
    };
  },
  mounted() {
    document.title = "网络文化经营许可证入口-网络文化经营许可证";
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.ewm {
  width: 100px;
  height: 100px;
  margin: 20px auto;
  img {
    width: 100%;
    height: 100%;
  }
}
// 上层
.ac2-title {
  width: 100%;
  height: 380px;
  background-color: #bd1a2d;
  box-sizing: border-box;

  // 上层
  .a2-s {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #d45054;
    .container {
      height: 100%;
      line-height: 60px;
      span {
        font-size: 14px;
        color: #fff;
        margin: 0 10px;
        cursor: pointer;
      }
    }
  }

  // 下层
  .a2-x {
    width: 100%;
    height: 330px;
    .container {
      height: 100%;
      @include flex();
      // 左侧
      .a2-l {
        width: 40%;
        height: 100%;
        // background: #000;
        // 标题
        h2 {
          font-size: 40px;
          font-weight: 400;
          color: #fff;
          padding-top: 60px;
          box-sizing: border-box;
          margin-bottom: 60px;
        }

        span {
          width: 170px;
          height: 60px;
          text-align: center;
          // line-height: 60px;
          border: 2px solid #fff;
          display: inline-block;
          margin-right: 65px;
          font-size: 18px;
          padding: 15px;
          box-sizing: border-box;
          color: #fff;
          cursor: pointer;
          &:hover {
            background-color: #fff;
            color: #c60023;
          }
        }
      }
      // 右侧
      .a2-r {
        width: 40%;
        height: 100%;
        // background: #ff6600;
        padding: 30px 80px;
        box-sizing: border-box;
        p {
          font-size: 16px;
          color: #fbf7f7;
          margin: 60px auto;
        }
      }
    }
  }
}

.promise {
  height: 355px;
}

.swiper-container {
  height: 295px;
}

table {
  width: 777px;
  border: 1px #d4d4d4 solid;
  border-collapse: collapse;
  table-layout: fixed;
  word-break: break-all;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
table td {
  border: 1px #d4d4d4 solid;
  font-size: 16px;
  box-sizing: border-box;
  padding: 10px 5px 10px 5px;
  line-height: 25px;
}
.path-name {
  text-align: center;
}

.task-title {
  height: 306px;
  background-color: #bd1a2d;
  position: relative;
}
.bottom-x {
  position: absolute;
  top: 55px;
  background-color: #d45054;
  width: 100%;
  height: 1px;
}
.title-main {
  width: 1200px;
  margin: 0 auto;
  color: #ffffff;
  height: 306px;
  // background-image: url("../image/taskBack.png");
}
.task-adds {
  display: inline-block;
  width: 1085px;
  height: 54px;
  line-height: 54px;
  font-size: 16px;
  vertical-align: top;
}
.task-adds a {
  color: #ffffff;
}
.task-adds span {
  margin: 0 5px;
}
.succinct {
  display: inline-block;
  width: 108px;
  border-right: 1px solid #d45054;
  border-left: 1px solid #d45054;
  height: 54px;
  line-height: 54px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}
.tit-name {
  font-size: 40px;
  margin: 62px 0 30px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.task-btn a {
  display: inline-block;
  /*width: 177px;*/
  height: 56px;
  line-height: 56px;
  border: 2px solid #ffffff;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  font-size: 19px;
  margin-right: 10px;
  padding: 0 40px;
  color: #ffffff;
}
.task-btn a:hover {
  background-color: #ffffff !important;
  color: #cd0600 !important;
}
.task-btn span {
  display: inline-block;
  margin-left: 25px;
  cursor: pointer;
  /*vertical-align: bottom;*/
}
.task-sp-d {
  float: right;
  margin-top: 43px;
}
.task-sp-d-one {
  margin-bottom: 15px;
}
.task-sp-d-two {
  margin-left: 4px;
}
.task-btn img {
  vertical-align: top;
  margin-right: 10px;
}
.phoneY {
  position: relative;
}
.phoneZ {
  position: relative;
}
.phoneZ:hover,
.phoneY:hover {
  background-color: #ffffff !important;
  color: #cd0600 !important;
  transition: all 0.3s ease;
}
.YD {
  width: 540px;
  height: 200px;
  background-color: #ffffff;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 2;
  left: 0;
  top: 60px;
  color: #000;
}
.ZD {
  width: 350px;
  height: 107px;
  background-color: #ffffff;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 2;
  left: 0;
  top: 60px;
  color: #000;
}
.ZD > p {
  height: 107px;
  line-height: 107px;
  font-size: 16px;
  margin: 0;
}
.ZD img {
  vertical-align: middle;
}

.YD > p {
  font-size: 18px;
  text-align: left;
  line-height: 25px;
  display: block !important;
}
.YD > p:nth-child(1) {
  margin: 45px 0 0 40px;
}
.YD img {
  margin-left: 85px;
  vertical-align: middle;
}
.YD > p span {
  font-size: 15px;
  line-height: 25px;
  margin-left: 0;
}
.taskP1 {
  margin: 10px 0 0 0;
}
.taskP1 > span {
  font-weight: bolder;
}
.see-SP {
  position: relative;
}
.erwei {
  position: absolute;
  left: 0;
  top: 25px;
  background-color: #ffffff;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 15px;
  z-index: 4;
}
.task-btn-set {
  background-color: #ffffff !important;
  color: #cd0600 !important;
}
.table-center {
  border-top: 1px solid transparent;
}
.table-main {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}
.table-name {
  width: 777px;
  color: #343434;
  font-size: 24px;
  margin: 61px 0 30px 0;
  font-weight: 600;
}

.basic-main table,
#path-table,
#apply-table,
#sample-table table,
#setting-table table,
.charge-table,
#charge-table {
  width: 777px;
  border: 1px #d4d4d4 solid;
  border-collapse: collapse;
  table-layout: fixed;
  word-break: break-all;
}
.charge-table {
  margin-top: 30px;
}
.basic-main {
  position: relative;
}
.apply-main {
  position: relative;
}
.more {
  position: absolute;
  width: 100%;
  height: 38px;
  font-size: 15px;
  background-color: #f0f0f0;
  text-align: center;
  line-height: 38px;
  font-weight: bolder;
  cursor: pointer;
  bottom: -45px;
  z-index: 2;
}
.more img {
  vertical-align: top;
  margin: 14px 0 0 10px;
  transition: all 0.3s ease;
}
table td {
  border: 1px #d4d4d4 solid;
  font-size: 16px;
  box-sizing: border-box;
  padding: 10px 5px 10px 5px;
  line-height: 25px;
}
.td-name {
  width: 122px;
  background-color: #fafafa;
  color: #444444;
  font-weight: 600;
  line-height: 25px;
}
.skipSP {
  display: block;
  color: #cd0600;
  cursor: pointer;
}
#path-table th,
#apply-table th,
#sample-table table th,
#setting-table table th,
.charge-table th,
#charge-table th {
  background-color: #fafafa;
  font-size: 16px;
  text-align: center;
  border: 1px #d4d4d4 solid;
  height: 40px;
  line-height: 40px;
}
._table1 {
  width: 100%;
  border-collapse: collapse;
  padding: 0;
  margin: 0;
  border: none;
}
._td {
  padding: 10px 0 !important;
}
._table1 tr td {
  border: none;
  text-align: center;
}
._td1 {
  border-bottom: 1px solid #d4d4d4 !important;
}
.isFee {
  font-size: 16px;
}
.isFee img {
  vertical-align: top;
  margin: 1px 7px 0 10px;
}
.kuan {
  display: inline-block;
  width: 10px;
}
.path-name {
  text-align: center;
}
.new_input {
  margin: 0 5px;
}
.main-left {
  color: #838384;
  font-size: 16px;
  position: absolute;
  left: 0px;
  padding-right: 20px;
  border-right: 1px solid #d4d4d4;
  top: 117px;
}
.main-left-top {
  position: fixed !important;
}
.main-left a {
  display: block;
  width: 115px;
  text-align: right;
  margin-bottom: 30px;
  color: #838384;
}
._hov {
  display: block;
  /*transition: all .3s ease;*/
  width: 4px !important;
  height: 18px;
  background-color: #cd0600;
  position: absolute;
  right: 0;
}
.main-center {
  width: 777px;
  float: left;
  padding-bottom: 40px;
  margin-left: 150px;
}
.main-right {
  width: 250px;
  margin-left: 20px;
  float: left;
  margin-top: 118px;
}
.promise {
  box-sizing: border-box;
  padding: 20px 15px 0px 20px;
  border: 1px #d4d4d4 solid;
  background-color: #fafafa;
}
.promise-title {
  font-weight: bolder;
  font-size: 16px;
}
.promise p {
  margin-bottom: 10px;
  line-height: 23px;
}
.promise span {
  display: inline-block;
  margin: 6px 0;
  font-size: 15px;
  width: 55px;
}
.promise-sp-title {
  width: 103px;
  color: #a2a2a2;
  font-size: 15px;
  text-align: left;
}
.Promise-title {
  color: #a2a2a2;
  text-align: left;
}
.Promise-title img {
  width: 15px;
  float: right;
  margin-top: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.crsx-d,
.blsd-d {
  margin-bottom: 10px;
}
.zx-div,
.ts-div,
.cjwt,
.pingJ {
  margin-bottom: 35px;
}
.zx-div p,
.ts-div p {
  word-break: break-all;
  word-wrap: break-word;
}
.p-ran {
  padding-bottom: 20px;
  border-bottom: 1px solid #d4d4d4;
}
.p-ran a {
  font-weight: normal;
  float: right;
  color: #cd0600;
}
.p-ran img {
  width: 15px;
  float: right;
  margin-top: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  transform: rotate(-180deg);
}
.pingJ p {
  position: relative;
}
.pingJ p span {
  font-size: 14px;
}
.last-sp {
  float: right;
}
.pj-sp,
.pj-sp-red {
  position: absolute;
  display: inline-block;
  height: 8px;
  top: 7px;
  left: 74px;
}
.pj-sp {
  transition: all 0.3s ease;
  width: 100px;
  /*background-color: #e1e1e1;*/
}
.pj-sp-red {
  background-color: #e1e1e1;
}
.kb {
  width: 70px;
  text-align: center;
  display: block;
  padding: 5px 5px;
  border: 1px solid #d1564f;
  color: #d1564f;
  margin: 5px 0;
  cursor: pointer;
}
.yb {
  width: 70px;
  text-align: center;
  display: block;
  padding: 5px 5px;
  background: #d1564f;
  color: #ffffff;
  margin: 5px 0;
}
.gang {
  /*color: #cd0600;*/
  /*font-weight: bold;*/
  display: inline-block;
  width: 100%;
  text-align: center;
}
.pj-A {
  display: none;
}
.yytSp {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.leftTitleclamp {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 20;
  overflow: hidden;
}
.leftTitleclampW {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  overflow: hidden;
}
.rightTitleclamp {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}
.zkai {
  cursor: pointer;
  color: #cd0600;
}
.prompt-box {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  z-index: 999;
}
.promptmain {
  width: 540px;
  left: 50%;
  top: 25%;
  position: relative;
  transform: translate(-50%, -25%);
}
.promptTitle-div {
  height: 68px;
  background-color: #cd0600;
  box-sizing: border-box;
  padding: 12px 10px 25px 37px;
}
.prompt-title {
  color: #ffffff;
  font-size: 21px;
  margin-top: 10px;
  float: left;
}
.prompES {
  float: right;
}
.promptcontent {
  box-sizing: border-box;
  padding: 34px 37px 50px 37px;
  font-size: 16px;
  background-color: #ffffff;
  overflow-y: scroll;
}
._hidden {
  display: none;
}
.clearfix {
  zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
}
.clearfix:after {
  clear: both;
}
</style>
