<template>
  <div class="c2">
    <div class="container">
      <!-- 导航 -->
      <nav class="c2-list1">
        <ul class="index_nav clearfix">
          <!-- 申请网络文化经营许可证入口 -->
          <li>
            <a
              href="javascript:;"
              title="申请网络文化经营许可证入口"
              @click="goAc2()"
              >申请网络文化经营许可证入口</a
            >
            <div class="nav_item">
              <ul style="padding-left: 52px;">
                <li
                  v-for="(item, index) in this.licenceList"
                  :key="index"
                  @click="goAc2()"
                >
                  <a href="javascript:;" title="新办">{{ item.title }}</a>
                </li>
              </ul>
            </div>
          </li>

          <!-- 行业资讯 -->
          <li style="margin-left: 110px">
            <a href="javascript:;" title="要闻动态" @click="goAc1()"
              >行业资讯</a
            >
            <div class="nav_item nav_item1">
              <ul>
                <!-- 循环项 -->
                <li
                  v-for="(item, index) in this.politicsList"
                  :key="index"
                  @click="goAc1()"
                >
                  <a href="javascript:;" title="item.title">{{ item.title }}</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>

      <!-- 资讯区块 + 轮播 -->
      <div class="c2-con">
        <!-- 左侧 -->
        <div class="c2-l">
          <!-- 资讯标题 -->
          <div class="c2-title">
            <a href="javascript:;" @click="goDetails(1)"
              >企业办理互联网资质的必要性</a
            >
          </div>
          <!-- 资讯内容 -->
          <p class="c2-txt">
            企业资质是对企业相关资格的认证标准，资质管理是我国经济市场监督管理的有效措施，政府部门相关人员通过制定资质制度，可以对企业人力资源管理水平、经济实力以及业务能力等进行客观的评估，在审查后可以确定企业应当承担的发展任务，可以为企业出具相关证明，提供资格证书。加强企业资质管理可以保证企业稳定的运行，资质是对企业相关资格的认证标准。
          </p>

          <!-- 按钮 -->
          <div class="c2-btn">
            <!-- 点击时间，传ID -->
            <a href="javasciprt:;" @click="goDetails(18)">查看详情</a>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="c2-r">
          <swiper :options="swiperOption">
            <!-- 循环想 -->
            <swiper-slide v-for="(item, index) in this.swiperList" :key="index">
              <a href="javascript:;" @click="goDetails(item.id)"
                ><img :src="item.img"
              /></a>
            </swiper-slide>
            <!-- <div class="swiper-pagination" slot="pagination"></div> -->
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import "swiper/css/swiper.css";
export default {
  naem: "cs",
  data() {
    return {
      tabShow: 0,
      // 轮播配置
      swiperOption: {
        autoplay: true,
        loop: true,
        effect: "fade",
        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      // 轮播数组
      swiperList: [
        {
          id: "1",
          img: "/imgs/banner1.jpg",
        },
        {
          id: "2",
          img: "/imgs/swiper3.jpg",
        },
        {
          id: "3",
          img: "/imgs/swiepr2.jpg",
        },
      ],
      // 政治解读 数组
      politicsList: [
        {
          id: "1",
          title: "网络文化经营许可证资讯",
        },
        {
          id: "2",
          title: "ICP资讯",
        },
        {
          id: "3",
          title: "EDI资讯",
        },
      ],
      // 网络文化经营许可证 数组
      licenceList: [
        {
          id: "1",
          title: "新办",
        },
        {
          id: "2",
          title: "变更",
        },
        {
          id: "3",
          title: "续期",
        },
        {
          id: "4",
          title: "年报",
        },
      ],
    };
  },
  methods: {
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { id: id },
      });
    },
    goAc1() {
      this.$router.push("/ac1");
    },
    goAc2() {
      this.$router.push("/ac2");
    },
    del() {
      alert("提交成功!");
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.clearfix:after {
  content: "\200B";
  display: block;
  height: 0;
  clear: both;
}
.clearfix {
  *zoom: 1;
}
.index_nav {
  // padding: 20px 0 70px;
  position: relative;
}
.index_nav li {
  float: left;
  margin-right: 25px;
  font-size: 24px;
  line-height: 2em;
  font-weight: bold;
}

.index_nav li a {
  color: #fff;
}
.index_nav li .nav_item {
  display: none;
  // display: block;
  position: absolute;
  text-align: left;
  left: 0;
  top: 45px;
  width: 170%;
}
.index_nav li .nav_item1 {
  text-align: center;
  padding-left: 45px;
  // display: block;
}
.index_nav li .nav_item li {
  float: none;
  font-size: 16px;
  background: 0;
  display: inline;
}
.index_nav li:hover .nav_item {
  display: block;
}
.index_nav li .nav_item li a:hover {
  color: #f5d389;
}

.c2 {
  width: 100%;
  height: 700px;
  background: url("/imgs/bj2.png") no-repeat center;
  margin-top: -100px;
  .container {
    padding-top: 80px;
    position: relative;

    // 下层导航
    .c2-list1 {
      width: 68%;
      height: 40px;
      box-sizing: border-box;
      margin-bottom: 60px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      // 左侧
      .c2-s-l {
        position: relative;
        // width: 350px;
        height: 100%;
        &:hover span {
          // border-bottom: 3px solid #f5d389;
        }
        &:hover ul {
          opacity: 1;
        }
        span {
          font-size: 24px;
          color: #fff;
          cursor: pointer;
          height: 75px;
          display: inline-block;
          width: 300px;
          text-align: center;
        }
        ul {
          width: 450px;
          // height: 100%;
          z-index: 20;
          position: absolute;
          top: 35px;
          left: 0;

          li {
            float: left;
            margin: 0 5px;
            a {
              color: #fff;
              font-size: 16px;
            }
          }
        }
      }
      // 右侧
      .c2-s-r {
        position: relative;
        // width: 350px;
        height: 100%;
        &:hover span {
          // border-bottom: 3px solid #f5d389;
        }
        &:hover ul {
          opacity: 1;
        }
        span {
          font-size: 24px;
          color: #fff;
          cursor: pointer;
          height: 64px;
          display: inline-block;
          position: relative;
        }
        ul {
          width: 450px;
          height: 20px;
          position: absolute;
          top: 40px;
          left: 13px;
          opacity: 1;
          // background-color: #666;

          li {
            float: left;
            margin: 0 15px;
            a {
              color: #fff;
              font-size: 16px;
            }
          }
        }
      }
    }

    // 资讯区块 + 轮播
    .c2-con {
      width: 100%;
      height: 350px;
      // background-color: #fff;
      @include flex();

      // 左侧
      .c2-l {
        width: 50%;
        height: 100%;
        // background-color: #ff6600;
        // padding-top: 30px;
        box-sizing: border-box;
        padding-right: 50px;
        // 资讯标题
        .c2-title {
          margin-bottom: 30px;
          a {
            font-size: 31px;
            font-weight: bold;
            color: #fff;
          }
        }
        // 资讯内容
        .c2-txt {
          font-size: 14px;
          color: #eee;
          line-height: 40px;
        }

        // 按钮
        .c2-btn {
          margin-top: 30px;
          a {
            font-size: 24px;
            height: 40px;
            color: #fff;
            display: inline-block;
            border-bottom: 1px solid #f5d389;
          }
        }
      }
      // 右侧
      .c2-r {
        width: 50%;
        height: 100%;
        background-color: #fff;
      }
    }

    // swiepr ---------------------------
    .swiper-container {
      height: 100%;
    }
  }
}
</style>
