<template>
  <div class="sound">
    <div class="c1">
      <div class="container">
        <!-- 下层容器 -->
        <div class="c1-con">
          <!-- logo -->
          <div class="c2-logo">
            <!-- <img src="/imgs/logo.jpg" alt="" /> -->
          </div>
          <!-- 标题文字 -->
          <div class="c2-title">
            <h2 @click="goHome()" style="cursor: pointer">
              网络文化经营许可证
            </h2>
          </div>
          <!-- 英文标题 -->
          <div class="c2-title2">
            <h2>Business license of network culture</h2>
          </div>
          <!-- input 搜索框 -->
          <div class="c2-input">
            <input
              type="text"
              placeholder="大家都在搜"
              v-model="vals"
              @keyup.13="gets()"
            />
            <span @click="gets()"
              >搜索 <i class="iconfont icon-jinru"></i
            ></span>
          </div>
          <!-- 热词搜索 -->
          <div class="c2-search">
            <span>热搜词：</span>
            <a
              href="javascript:;"
              v-for="(item, index) in this.search_list"
              :key="index"
              @click="goDetails(item.id)"
              >{{ item.name }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 主容器 -->
    <div class="s-con">
      <div class="container">
        <!-- 面包屑导航 -->
        <nav class="ac1-list">
          <a href="javascript:;" @click="goHome()">首页</a>
          <span>></span>
          <a href="javascript:;" @click="goAc()">关于我们</a>
        </nav>

        <!-- 文章容器 -->
        <div class="s-box">
          <!-- 文章标题 -->
          <h2>关于我们</h2>

          <!-- 文章信息层 -->
          <div class="s-info">
            <!-- 时间 -->
            <div class="s-time">2020-2-22</div>
            <!--来源 / 作者  -->
            <div class="s-name">来源： 坚果创世（北京）网络科技有限公司</div>
          </div>

          <!-- 文章内容 -->
          <div class="s-text">
            <!-- 简介，放在第一行 -->
            <div class="s-jj">
              {{ this.jj }}
            </div>

            <!-- 标题，剧中，颜色要不一样 -->
            <h2>{{ this.theme }}</h2>

            <p>
              坚果创世（北京）网络科技有限公司成立于2019年，注册资金100万，地址位于北京市通州区富力运河十号B02楼2020号。我公司是一家专业从事工商注册、企业咨询服务、企业互联网经营领域代理、资质准入等业务的综合服务型公司。
            </p>
            <p>
              我公司是一家年轻的创业型公司，公司员工均为业内多年从业经验的职业从业人员，在业内具有良
              好的口碑，员工多年优秀的服务也为新公司提供了良好的服务平台，我们有实力为您提供专业的技术团
              队、强大的项目支持以及贴心的销售服务，解决您在该领域的后顾之忧。
            </p>
            <p>
              公司致力于为企业提供专业的一站式企业服务，秉承相聚是缘，用心服务、面面俱到的服务理念，
              为客户提供放心、满意的优质服务，让您诚意而来，满意而归是我们奋斗的目标
            </p>
            <p>
              {{ this.p4 }}
            </p>
            <p>
              {{ this.p5 }}
            </p>
            <p>
              {{ this.p6 }}
            </p>
            <p>
              {{ this.p7 }}
            </p>
            <p>
              {{ this.p8 }}
            </p>

            <!-- 二维码 -->
            <div class="a3-img">
              <img src="" alt="" />
            </div>

            <!-- 联系方式 -->
            <div class="a3-info">
              <ul>
                <li style="font-size:21px;font-weight;">联系方式：</li>
                <li>公司：坚果创世（北京）网络科技有限公司</li>
                <li>邮箱：jianguo@jg15.onexmail.com</li>
                <li>热线：400-006-2021</li>
                <li>地址：北京市通州区富力运河十号B02楼2020号</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sound",
  components: {},
  data() {
    return {
      // 获取id
      myId: this.$route.query.id,
      // 文章标题
      title: "",
      // 发布时间
      time: "",
      // 来源 、 作者
      name: "",
      // 简介
      jj: "",
      // 文章中心
      theme: "",
      // 第一段
      p1: "",
      // 第二段
      p2: "",
      // 第三段
      p3: "",
      // 第四段
      p4: "",
      // 第五段
      p5: "",
      // 第六段
      p6: "",
      // 第七段
      p7: "",
      // 第八段
      p8: "",
      // 搜索框内容
      vals: "",
      // 热搜词循环
      search_list: [
        {
          id: "13",
          name: "疫情防控",
        },
        {
          id: "14",
          name: "失业保险金申领",
        },
        {
          id: "15",
          name: "核算检测",
        },
        {
          id: "16",
          name: "优化运营环境",
        },
        {
          id: "17",
          name: "垃圾分类",
        },
      ],
    };
  },
  mounted() {
    this.init();
    document.title = "关于我们-坚果创世（北京）网络科技有限公司";
  },
  methods: {
    init() {
      this.axios.get("/mock/info.json").then((res) => {
        var vl = res.data;
        // 根据 id 获取相应的对象
        var data = vl.find((vl) => vl.id == this.myId);
        // 网站标题
        this.title = data.title;
        // 发布时间
        this.time = data.time;
        // 来源
        this.name = data.name;
        // 简介
        this.jj = data.jj;
        // 文章中心
        this.theme = data.theme;
        // 第一段
        this.p1 = data.p1;
        // 第二段
        this.p2 = data.p2;
        // 第三段
        this.p3 = data.p3;
        // 第四段
        this.p4 = data.p4;
        // 第五段
        this.p5 = data.p5;
        // 第六段
        this.p6 = data.p6;
        // 第七段
        this.p7 = data.p7;
        // 第八段
        this.p8 = data.p8;
      });
    },
    // 跳转文章页
    goAc() {
      this.$router.push("/ac1");
    },
    // 跳转首页
    goHome() {
      this.$router.push("/");
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { id: id },
      });
      this.$router.go(0);
    },
    gets() {
      if (this.vals == "") {
        alert("搜索关键字不能为空");
        return;
      }
      let jsonData = require("../../public/mock/info.json");
      let id = null;
      console.log(jsonData);
      for (var i = 0; i < jsonData.length; i++) {
        if (jsonData[i].title.indexOf(this.vals) != -1) {
          id = jsonData[i].id;
          break;
        }
      }
      if (id != null) {
        this.goDetails(id);
      } else {
        alert("搜索结果为空");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.sound {
  background-color: #f2f2f2;
  //   外层主容器
  .s-con {
    width: 100%;
    // height: 1200px;
    .container {
      height: 100%;
      padding: 30px;
      margin: 10px auto;
      box-sizing: border-box;
      background-color: #fff;
      // 面包屑导航
      .ac1-list {
        width: 100%;
        height: 30px;

        margin-bottom: 15px;
        a {
          font-size: 14px;
          color: #121212;
        }
        span {
          margin: 0 15px;
          font-weight: bold;
        }
      }

      // 文章容器
      .s-box {
        width: 100%;
        height: 90%;
        border: 1px solid #dddddd;
        padding: 20px 70px;
        box-sizing: border-box;
        // 文章标题
        h2 {
          line-height: 56px;
          font-family: "微软雅黑", "宋体";
          font-size: 38px;
          text-align: center;
          padding: 26px 0 26px 0;
          font-weight: normal;
        }

        // 文章信息
        .s-info {
          width: 100%;
          height: 60px;
          // background-color: pink;
          display: flex;
          align-items: center;
          font-size: 13px;
          border-bottom: 1px #dcdcdc solid;
          color: #666;
          div {
            margin-right: 30px;
          }
        }

        // 文章内容
        .s-text {
          margin-top: 30px;
          width: 100%;
          letter-spacing: 1px;
          // 简介
          .s-jj {
            text-indent: 2em;
            font-family: 宋体;
            font-size: 12pt;
          }

          // 标题
          h2 {
            font-weight: bold;
            font-family: 宋体;
            color: rgb(128, 0, 0);
            font-size: 16px;
            margin-bottom: 30px;
          }

          //  文章
          p {
            text-indent: 2em;
            font-family: 宋体;
            font-size: 12pt;
            margin: 15px auto;
            line-height: 30px;
          }
          //   二维码
          .a3-img {
            width: 250px;
            height: 250px;
            margin: 30px auto;
            img {
              width: 100%;
              height: 100%;
            }
          }

          // 联系方式列表
          .a3-info {
            li {
              font-size: 14px;
              margin: 15px auto;
            }
          }
        }
      }
    }
  }
  .c1 {
    width: 100%;
    height: 600px;
    background: url("/imgs/bj3.jpg") no-repeat center top;
    // background-size: cover;

    //   margin-bottom: 100px;
    //   background-color: pink;
    .container {
      height: 100%;
      position: relative;
      box-sizing: border-box;
      padding-top: 100px;
      // 绝对定位导航
      .c1-nav {
        width: 600px;
        height: 100px;
        position: absolute;
        display: flex;
        align-items: center;
        top: 30px;
        left: 0;
        // background-color: #fff;
        // logo
        .c1-logo {
          width: 200px;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        // 右侧信息
        .c1-phone {
          margin-left: 30px;
          height: 60px;
          display: flex;
          align-items: center;
          color: #fff;
          i {
            font-size: 35px;
            font-weight: bold;
            margin-right: 10px;
          }
          .phone-info {
            p {
              font-size: 14px;
              margin: 8px auto;
            }
          }
        }
      }
      // 主容器
      .c1-con {
        text-align: center;
        //   background-color: #ff6600;
        width: 700px;
        height: 500px;
        margin: 0 auto;

        //logo
        .c2-logo {
          width: 200px;
          height: 100px;
          margin: 50px auto 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        //标题文字
        .c2-title {
          font-size: 26px;
          color: #c60023;
        }
        //英文标题
        .c2-title2 {
          font-size: 14px;
          color: #928d8d;
          margin: 10px auto 30px;
        }
        //搜索框
        .c2-input {
          width: 700px;
          height: 60px;
          //   background-color: #ff6600;
          display: flex;
          align-items: center;
          //   border-radius: 10px;
          //   input框
          input {
            height: 100%;
            width: 83%;
            border-style: none;
            border-radius: 10px 0px 0px 10px;
            // background-color: #c60023;
            box-sizing: border-box;
            padding-left: 20px;
            // border: 5px solid #eee;
          }
          // 搜索
          span {
            width: 17%;
            height: 100%;
            background-color: #c60023;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            border-radius: 0 10px 10px 0;
            color: #fff;
            cursor: pointer;
            i {
              font-size: 28px;
              margin-left: 5px;
            }
          }
        }

        //热搜词
        .c2-search {
          margin-top: 30px;
          text-align: left;
          span {
            font-size: 14px;
            // font-weight: bold;
            margin-right: 15px;
          }
          a {
            font-size: 14px;
            color: #121212;
            margin: 0 15px;
            &:hover {
              color: #c60023;
            }
          }
        }
      }
    }
  }
}
</style>
