<template>
  <div class="sound">
    <!-- <nav-header></nav-header> -->
    <div class="c1">
      <div class="container">
        <!-- 上层导航 绝对定位 -->
        <div class="c1-nav">
          <!-- 左侧logo -->
          <div class="c1-logo"></div>
          <!-- 右侧信息 -->
          <div class="c1-phone"></div>
        </div>
        <!-- 下层容器 -->
        <div class="c1-con">
          <!-- logo -->
          <div class="c2-logo"></div>
          <!-- 标题文字 -->
          <div class="c2-title">
            <h2 @click="goHome()" style="cursor: pointer">
              网络文化经营许可证
            </h2>
          </div>
          <!-- 英文标题 -->
          <div class="c2-title2">
            <h2>Business license of network culture</h2>
          </div>
          <!-- input 搜索框 -->
          <div class="c2-input">
            <input
              type="text"
              placeholder="大家都在搜"
              v-model="vals"
              @keyup.13="gets()"
            />
            <span @click="gets()"
              >搜索 <i class="iconfont icon-jinru"></i
            ></span>
          </div>
          <!-- 热词搜索 -->
          <div class="c2-search">
            <span>热搜词：</span>
            <a
              href="javascript:;"
              v-for="(item, index) in this.search_list"
              :key="index"
              @click="goDetails(item.id)"
              >{{ item.name }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 主容器 -->
    <div class="s-con">
      <div class="container">
        <!-- 面包屑导航 -->
        <nav class="ac1-list">
          <a href="javascript:;" @click="goHome()">首页</a>

          <span>></span>
          <a href="javascript:;">{{ this.title }}</a>
        </nav>

        <!-- 文章容器 -->
        <div class="s-box">
          <!-- 文章标题 -->
          <h2>{{ this.title }}</h2>

          <!-- 文章内容 -->
          <div class="s-text">
            <!-- 正本 -->
            <div class="s-img">
              <img :src="zb" alt="" />
            </div>
            <!-- 副本 -->
            <div class="s-img">
              <img :src="fb" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NavHeader from "./../components/NavHeader";
// import c3 from "./../components/c3";
export default {
  name: "sound",
  components: {
    // NavHeader,
    // c3,
  },
  data() {
    return {
      // 时间
      tdtime: "",
      // 获取id
      myId: this.$route.query.id,
      // 搜索框内容
      vals: "",
      // 热搜词循环
      search_list: [
        {
          id: "13",
          name: "直播",
        },
        {
          id: "14",
          name: "直播许可证",
        },
        {
          id: "15",
          name: "文网文",
        },
        {
          id: "16",
          name: "网络表演",
        },
        {
          id: "17",
          name: "直播许可证代办",
        },
      ],
      // 公司 title
      title: "",
      // 正本
      zb: "",
      // 副本
      fb: "",
    };
  },
  mounted() {
    this.init();
    var myDate = new Date();
    this.tdtime = myDate.toISOString().slice(0, 10);
  },
  methods: {
    init() {
      this.axios.get("/mock/dn.json").then((res) => {
        var vl = res.data;
        // 根据 id 获取相应的对象
        var data = vl.find((vl) => vl.id == this.myId);
        document.title = data.title + "-网络文化经营许可证";
        // 公司 title
        this.title = data.title;
        // 正本
        this.zb = data.imgz;
        // 副本
        this.fb = data.imgf;
      });
    },
    // 跳转文章页
    goAc() {
      this.$router.push("/ac1");
    },
    // 跳转首页
    goHome() {
      this.$router.push("/");
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { id: id },
      });
      this.$router.go(0);
    },
    gets() {
      if (this.vals == "") {
        alert("搜索关键字不能为空");
        return;
      }
      let jsonData = require("../../public/mock/info.json");
      let id = null;
      console.log(jsonData);
      for (var i = 0; i < jsonData.length; i++) {
        if (jsonData[i].title.indexOf(this.vals) != -1) {
          id = jsonData[i].id;
          break;
        }
      }
      if (id != null) {
        this.goDetails(id);
      } else {
        alert("搜索结果为空");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.sound {
  background-color: #f2f2f2;
  //   外层主容器
  .s-con {
    width: 100%;
    // height: 1200px;
    .container {
      height: 100%;
      padding: 30px;
      margin: 10px auto;
      box-sizing: border-box;
      background-color: #fff;
      // 面包屑导航
      .ac1-list {
        width: 100%;
        height: 30px;

        margin-bottom: 15px;
        a {
          font-size: 14px;
          color: #121212;
        }
        span {
          margin: 0 15px;
          font-weight: bold;
        }
      }

      // 文章容器
      .s-box {
        width: 100%;
        height: 90%;
        border: 1px solid #dddddd;
        padding: 20px 70px;
        box-sizing: border-box;
        // 文章标题
        h2 {
          line-height: 56px;
          font-family: "微软雅黑", "宋体";
          font-size: 38px;
          text-align: center;
          padding: 26px 0 26px 0;
          font-weight: normal;
        }

        // 文章信息
        .s-info {
          width: 100%;
          height: 60px;
          // background-color: pink;
          display: flex;
          align-items: center;
          font-size: 13px;
          border-bottom: 1px #dcdcdc solid;
          color: #666;
          div {
            margin-right: 30px;
          }
        }

        // 文章内容
        .s-text {
          margin-top: 30px;
          width: 100%;
          letter-spacing: 1px;
          // 图片
          .s-img {
            width: 600px;
            height: 800px;
            margin: 20px auto;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .c1 {
    width: 100%;
    height: 600px;
    background: url("/imgs/bj3.jpg") no-repeat center top;
    // background-size: cover;

    //   margin-bottom: 100px;
    //   background-color: pink;
    .container {
      height: 100%;
      position: relative;
      box-sizing: border-box;
      padding-top: 100px;
      // 绝对定位导航
      .c1-nav {
        width: 600px;
        height: 100px;
        position: absolute;
        display: flex;
        align-items: center;
        top: 30px;
        left: 0;
        // background-color: #fff;
        // logo
        .c1-logo {
          width: 200px;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        // 右侧信息
        .c1-phone {
          margin-left: 30px;
          height: 60px;
          display: flex;
          align-items: center;
          color: #fff;
          i {
            font-size: 35px;
            font-weight: bold;
            margin-right: 10px;
          }
          .phone-info {
            p {
              font-size: 14px;
              margin: 8px auto;
            }
          }
        }
      }
      // 主容器
      .c1-con {
        text-align: center;
        //   background-color: #ff6600;
        width: 700px;
        height: 500px;
        margin: 0 auto;

        //logo
        .c2-logo {
          width: 200px;
          height: 100px;
          margin: 50px auto 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        //标题文字
        .c2-title {
          font-size: 26px;
          color: #c60023;
        }
        //英文标题
        .c2-title2 {
          font-size: 14px;
          color: #928d8d;
          margin: 10px auto 30px;
        }
        //搜索框
        .c2-input {
          width: 700px;
          height: 60px;
          //   background-color: #ff6600;
          display: flex;
          align-items: center;
          //   border-radius: 10px;
          //   input框
          input {
            height: 100%;
            width: 83%;
            border-style: none;
            border-radius: 10px 0px 0px 10px;
            // background-color: #c60023;
            box-sizing: border-box;
            padding-left: 20px;
            // border: 5px solid #eee;
          }
          // 搜索
          span {
            width: 17%;
            height: 100%;
            background-color: #c60023;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            border-radius: 0 10px 10px 0;
            color: #fff;
            cursor: pointer;
            i {
              font-size: 28px;
              margin-left: 5px;
            }
          }
        }

        //热搜词
        .c2-search {
          margin-top: 30px;
          text-align: left;
          span {
            font-size: 14px;
            // font-weight: bold;
            margin-right: 15px;
          }
          a {
            font-size: 14px;
            color: #121212;
            margin: 0 15px;
            &:hover {
              color: #c60023;
            }
          }
        }
      }
    }
  }
}
</style>
