<template>
  <div class="index">
    <div class="container">
      <!-- 上层 -->
      <c1></c1>
    </div>
    <!-- 下层 -->
    <c2></c2>

    <!-- 新闻。登录区块 -->
    <div class="c3-con">
      <div class="container">
        <!-- 左侧 -->
        <div class="c3-l">
          <!-- 标题 -->
          <div class="c3-title">
            <h2>每日动态</h2>
          </div>

          <!-- 列表 -->
          <ul class="c3-list">
            <!-- 循环项 *6 -->
            <li
              v-for="(item, index) in this.myData"
              :key="index"
              @click="goDetails2(item.id)"
            >
              <span>{{ item.title }}</span>
              <span>{{ tdtime }}</span>
            </li>
          </ul>
        </div>
        <!-- 右侧 -->
        <div class="c3-r">
          <div class="c3-box">
            <!-- 标题 -->
            <div class="box-title">
              <h2>我要咨询</h2>
            </div>
            <!-- 容器 -->
            <div class="box-con">
              <!-- 姓名 -->
              <input
                type="text"
                placeholder="请输入姓名"
                v-model="customerInfo.name"
              />
              <!-- 公司名称 -->
              <input
                type="text"
                placeholder="请输入公司名称"
                v-model="customerInfo.cpname"
              />
              <!-- 手机 -->
              <input
                type="text"
                placeholder="请输入手机号"
                v-model="customerInfo.phone"
              />
              <!-- 信息 -->
              <input
                type="text"
                placeholder="请输入邮箱"
                v-model="customerInfo.email"
              />
            </div>
            <!-- 按钮 -->
            <div class="box-btn">
              <span @click="del()">我要提交</span>
              <span>我要咨询</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="c2-r">
      <swiper :options="swiperOption">
        <!-- 循环项 -->
        <swiper-slide v-for="(item, index) in this.swiperList" :key="index">
          <a @click="goDetails(item.id)"><img :src="item.img" /></a>
        </swiper-slide>
        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
      </swiper>
    </div>
  </div>
</template>

<script>
import c1 from "./../components/Index_c1";
import c2 from "./../components/Index_c2";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "index",
  components: {
    c1,
    c2,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      tdtime: "",
      myData: [],
      name: "",
      cpname: "",
      phone: "",
      email: "",
      abc: [],
      // 公司信息
      customerInfo: {},
      // 轮播配置
      swiperOption: {
        autoplay: true,
        loop: true,

        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      // 轮播数组
      swiperList: [
        {
          id: "1",
          img: "/imgs/dswiper1.jpg",
        },
        {
          id: "2",
          img: "/imgs/123.jpg",
        },
        {
          id: "3",
          img: "/imgs/swiepr2.jpg",
        },
      ],
    };
  },
  mounted() {
    this.init();
    document.title = "网络文化经营许可证";
    var myDate = new Date();
    this.tdtime = myDate.toISOString().slice(0, 10);
  },
  methods: {
    init() {
      this.axios.get("/mock/dn.json").then((res) => {
        const data = res.data;
        this.myData = data.splice(0, 6);
      });
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { id: id },
      });
    },
    del() {
      // this.$router.go(0);
      this.axios.get("/mock/addController.php").then((res) => {
        console.log(res);
      });
    },
    // 跳转详情页
    goDetails2(id) {
      this.$router.push({
        path: "/sound2/:" + id,
        query: { id: id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.index {
  width: 100%;
  background: url("/imgs/bj3.jpg") no-repeat center top;
  height: 100%;

  .c3-con {
    height: 500px;
    margin-bottom: 60px;
    .container {
      // width: 100%;
      height: 100%;
      margin-top: -120px;
      @include flex();

      // 左侧
      .c3-l {
        width: 60%;
        height: 100%;
        // background-color: #ff6600;
        // 标题
        .c3-title {
          width: 100%;
          height: 70px;
          // background-color: #fff;
          text-align: center;
          h2 {
            font-size: 31px;
            line-height: 70px;
            font-weight: bold;
            color: #fff;
          }
        }
        // 列表
        .c3-list {
          width: 100%;
          height: 430px;
          li {
            width: 100%;
            height: 50px;
            margin: 15px auto;
            @include flex();
            padding: 0 25px;
            box-sizing: border-box;
            span {
              font-size: 18px;
              color: #121212;
              cursor: pointer;
              &:hover {
                color: #c60023;
              }
            }
          }
        }
      }
      // 右侧
      .c3-r {
        width: 40%;
        height: 100%;
        background-color: #fff;
        box-sizing: border-box;
        .c3-box {
          width: 90%;
          height: 85%;
          margin: 25px auto;
          background-color: #dcdcdc;
          padding-top: 20px;
          box-sizing: border-box;
          // 标题
          .box-title {
            margin-bottom: 30px;
            h2 {
              color: #fff;
              font-size: 48px;
              text-align: center;
            }
          }

          // 容器
          .box-con {
            text-align: center;
            input {
              width: 80%;
              height: 40px;
              background-color: #fff;
              margin: 5px auto;
              border-style: none;
              padding-left: 15px;
              box-sizing: border-box;
            }
          }

          // 按钮
          .box-btn {
            width: 100%;
            height: 70px;
            // background-color: #fff;
            margin-top: 30px;
            text-align: center;
            span {
              display: inline-block;
              width: 140px;
              height: 70px;
              border-radius: 5px;
              background-color: #a91728;
              color: #fff;
              text-align: center;
              line-height: 70px;
              font-size: 20px;
              font-weight: bold;
              margin: 0 30px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .c2-r {
    width: 1200px;
    height: 300px;
    background-color: #fff;
    margin: -84px auto 30px;
    .swiper-container {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
